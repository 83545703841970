import BANNER from "../assets/img/calliope_banner_2.png";

import "./SubHeader.scss";

const SubHeader = ({ title }) => {
  return (
    <div className="sub_header">
      <img src={BANNER} alt="" />
      <h1>{title}</h1>
    </div>
  );
};

export default SubHeader;
