import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import BANNER_1 from "../../assets/img/calliope_banner_1.png";

import ICON_NETWORK from "../../assets/icons/calliope_network.png";
import ICON_TYPE from "../../assets/icons/calliope_token_type.png";
import ICON_ADDRESS from "../../assets/icons/calliope_contract_address.png";
import ICON_SYMBOL from "../../assets/icons/calliope_token_symbol.png";
import ICON_ISSUANCE from "../../assets/icons/calliope_token_issuance.png";

import LISTED_MOCK_IMG from "../../assets/img/listed_mock_image.png";
import PARTNERS_MOCK_IMG from "../../assets/img/partners_mock.png";

import ICON_PHONE from "../../assets/icons/icon_phone.png";
import ICON_MAIL from "../../assets/icons/icon_mail.png";

import translationData from "../../locales/ko/translation.json";

// import WHITE_PAPER_KOR from "";
// import WHITE_PAPER_ENG from "";
// import WHITE_PAPER_KOR_ENG from "";

import "./Home.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";

const Home = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const bannerArr = [{ src: BANNER_1 }];

  const downloadPDFArr = [
    { name: "KOREAN", PDF: "" },
    { name: "ENGLISH", PDF: "" },
    { name: "KO / EN", PDF: "" },
  ];

  const pdfArr = [{ src: BANNER_1 }];

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 600 ? "70%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    display: "block",
    height: width > 600 ? "60%" : "50%",
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box>
        </Fade>
      </Modal>

      {/* 슬라이드 배너 섹션 */}
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        loop={true}
        autoplay={{ delay: 2500, disableOnInteraction: true }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {bannerArr.map((data, idx) => (
          <SwiperSlide key={idx + 1 + "banner"}>
            <div className="banner_img_wrap">
              <div>
                <strong>Calliope Token</strong>
                <span>{t(`bannerText.explain1`)}</span>
                <span>{t(`bannerText.explain2`)}</span>
              </div>
              <img src={data.src} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="wrapper">
        {/* 토큰 정보 섹션 */}
        <div className="home_token_info">
          <div>
            <div>
              <span>{t(`tokenInfo.catchphrase`)}</span>
              <strong>CALLIOPE TOKEN INFORMATION</strong>
            </div>

            <div>
              <div>
                <img src={ICON_NETWORK} alt="ICON_NETWORK" />
                <strong>Main Network</strong>
                <span>Ethereum</span>
              </div>
              <div>
                <img src={ICON_TYPE} alt="ICON_TYPE" />
                <strong>Type</strong>
                <span>ERC-20</span>
              </div>
              <div>
                <img src={ICON_ADDRESS} alt="ICON_ADDRESS" />
                <strong>Contract Address</strong>
                <span>0xfDA09cc66fceAD64A572D37f35DdE578c23a6063</span>
              </div>
              <div>
                <img src={ICON_SYMBOL} alt="ICON_SYMBOL" />
                <strong>Token Symbol</strong>
                <span>CALI</span>
              </div>
              <div>
                <img src={ICON_ISSUANCE} alt="ICON_ISSUANCE" />
                <strong>Total Issuance</strong>
                <span>900,000,000</span>
              </div>
            </div>
          </div>
        </div>

        {/* 토큰 사용처 정보 섹션 */}
        <div className="home_usable_point">
          <div>
            <strong>CALLIOPE USABLE POINT</strong>
            <p>{t(`usablePoint.info`)}</p>
          </div>

          <div>
            {translationData.usablePoint.whereToUse.map((data, idx) => (
              <p key={idx + 1 + "whereToUse"}>
                {t(`usablePoint.whereToUse.${idx}`)}
              </p>
            ))}
          </div>
        </div>

        {/* 토큰 백서 섹션 */}
        <div className="home_white_paper">
          <div>
            <div>
              <div>
                <span>CALLIOPEN TOKEN Deck</span>
                <strong>About CALLIOPE TOKEN</strong>
              </div>
              <div>
                <Button
                  style={{ backgroundColor: "#fff" }}
                  onClick={handleOpen}
                >
                  {t(`whitePaperSection.openPopupBtn`)}
                </Button>
                {/* <button onClick={() => {}}></button> */}
              </div>
            </div>

            <div>
              <div>
                {downloadPDFArr.map((data) => (
                  <button key={data.name}>
                    <a href={data.PDF} download={`${data.PDF}.pdf`}>
                      {data.name}
                    </a>
                  </button>
                ))}
              </div>
              <div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={50}
                  loop={true}
                  autoplay={{ delay: 2500, disableOnInteraction: true }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  {pdfArr.map((data, idx) => (
                    <SwiperSlide key={idx + 1 + "pdf"}>
                      <div className="banner_img_wrap">
                        <img src={data.src} alt="" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>

        {/* 상장 거래소 섹션 */}
        {/* <div className="home_listed">
          <strong>LISTED</strong>
          <span>{t(`listed.subtitle`)}</span>
          <div>
            <div>
              <img src={LISTED_MOCK_IMG} alt="" />
            </div>
          </div>
        </div> */}

        {/* 협력사 섹션 */}
        {/* <div className="home_partners">
          <strong>CALLIOPE TOKEN PARTNERS</strong>
          <span>{t(`partners.subtitle`)}</span>
          <div>
            <div>
              <img src={PARTNERS_MOCK_IMG} alt="" />
            </div>
          </div>
        </div> */}

        {/* 회사 정보 섹션 */}
        <div className="home_company_info">
          <strong>Contact Us</strong>
          <span>
            <img src={ICON_PHONE} alt="" />
            02-6953-1535
          </span>
          <span>
            <img src={ICON_MAIL} alt="" />
            help@orfeocultures.io
          </span>
        </div>
      </div>
    </>
  );
};

export default Home;
