import { useEffect, useState } from "react";
import ContentTitle from "../../components/ContentTitle";
import SubHeader from "../../components/SubHeader";
import MuiPagination from "../../components/MuiPagination";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import "./Notice.scss";

const REQUEST_URI = "https://admin-api-dev.oleamarket.com/api/notice";

const usePosts = () => {
  return useQuery({
    queryKey: ["posts"],
    queryFn: async () => {
      const { data } = await axios.get(REQUEST_URI);
      return data;
    },
  });
};

const Posts = ({ setPostId, setNoticeList }) => {
  const { status, data, isFetching } = usePosts();
  const [curPage, setCurPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [renderArr, setRenderArr] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searchRslt, setSearchRslt] = useState([]);

  const currentPosts = (tmp, curPage, postsPerPage) => {
    const last = curPage * postsPerPage;
    const first = last - postsPerPage;
    let cur = 0;
    if (tmp) {
      cur = tmp.slice(first, last);
    }
    return cur;
  };

  useEffect(() => {
    if (data) {
      setNoticeList(data.data);
      const flagList = data.data.filter((item) => item.flag === true);
      const normalList = data.data.filter((item) => item.flag === false);

      setRenderArr([...flagList, ...normalList]);
    }
  }, [data]);

  const findListWithKeyword = () => {
    const found = renderArr.filter((item) => item.title.includes(`${keyword}`));
    setSearchRslt(found);
  };

  return (
    <>
      {status === "loading" ? (
        "불러오는 중"
      ) : status === "error" ? (
        <span>공지사항을 불러오는 중 오류가 발생했습니다.</span>
      ) : (
        <div className="notice_table_wrap">
          <ul className="notice_top_border">
            <li>No</li>
            <li>제목</li>
            <li>게시일</li>
          </ul>
          {(searchRslt.length > 0
            ? searchRslt
            : currentPosts(renderArr, curPage, postsPerPage)
          ).map((data) => (
            <ul
              onClick={() => {
                setPostId(data.noticeId);
              }}
              key={data.noticeId}
              className={`clickable_notice ${
                data.flag ? "flag_notice" : undefined
              }`}
            >
              <li>{data.flag ? <FlagOutlinedIcon /> : data.noticeId}</li>
              <li>{data.title}</li>
              <li>{data.createdAt.split("T")[0]}</li>
            </ul>
          ))}
          <div>
            <input
              className="search_notice_handle_input"
              type="text"
              placeholder="검색어를 입력해 주세요."
              onChange={(event) => {
                event.preventDefault();
                setKeyword(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  findListWithKeyword();
                }
              }}
            />
            <button
              className="search_notice_handle_btn"
              onClick={() => {
                findListWithKeyword();
              }}
            >
              검색
            </button>
          </div>
          <MuiPagination
            count={Math.ceil(data.data.length / postsPerPage)}
            cur={curPage}
            setCur={setCurPage}
            postsPerPage={postsPerPage}
            setPostsPerPage={setPostsPerPage}
          />
          <div>{isFetching ? "새로운 공지사항을 불러오고 있습니다." : " "}</div>
        </div>
      )}
    </>
  );
};

const getPostById = async (id) => {
  const { data } = await axios.get(`${REQUEST_URI}/${id}`);
  return data;
};

function usePost(postId) {
  return useQuery({
    queryKey: ["post", postId],
    queryFn: () => getPostById(postId),
    enabled: !!postId,
  });
}

function Post({ postId, setPostId, noticeList }) {
  const { status, data, isFetching } = usePost(postId);
  const prevList = noticeList.filter((item) => item.noticeId === postId - 1);
  const nextList = noticeList.filter((item) => item.noticeId === postId + 1);
  const prevNextLists = [...prevList, ...nextList];

  return (
    <>
      {!postId || status === "loading" ? (
        "불러오는 중"
      ) : status === "error" ? (
        <span>공지사항을 불러오는 중 오류가 발생했습니다.</span>
      ) : (
        <div className="each_notice_wrap">
          <div className="goback_wrap">
            <span
              onClick={() => {
                setPostId(-1);
              }}
            >
              목록보기
            </span>
          </div>

          <div className="notice_info_wrap">
            <h2>{data.data.title}</h2>
            <span>게시일: {data.data.createdAt.split("T")[0]}</span>
            <div dangerouslySetInnerHTML={{ __html: data.data.content }}></div>
          </div>

          {prevNextLists.map((data) => (
            <div className="notice_table_wrap" key={data.noticeId}>
              <ul
                onClick={() => {
                  setPostId(data.noticeId);
                }}
                className={`clickable_notice ${
                  data.flag ? "flag_notice" : undefined
                }`}
              >
                <li>{data.noticeId > postId ? "다음" : "이전"}</li>
                <li>{data.title}</li>
                <li>{data.createdAt.split("T")[0]}</li>
              </ul>
            </div>
          ))}
          <div>{isFetching ? "새로운 공지사항을 불러오고 있습니다." : " "}</div>
        </div>
      )}
    </>
  );
}

const Notice = () => {
  const [postId, setPostId] = useState(-1);
  const [noticeList, setNoticeList] = useState([]);

  return (
    <div className="wrapper">
      <SubHeader title="NOTICE" />
      <ContentTitle title="notice" />

      {postId > -1 ? (
        <Post postId={postId} setPostId={setPostId} noticeList={noticeList} />
      ) : (
        <Posts setPostId={setPostId} setNoticeList={setNoticeList} />
      )}
    </div>
  );
};

export default Notice;
