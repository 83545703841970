import { Pagination } from "@mui/material";
import "./MuiPagination.scss";

const MuiPagination = ({
  count,
  cur,
  setCur,
  postsPerPage,
  setPostsPerPage,
}) => {
  return (
    <div className="mui_pagination_wrap">
      <Pagination
        shape="rounded"
        count={count}
        page={cur}
        className="paging"
        onChange={(e, value) => {
          setCur(value);
        }}
      />
      {/* <div>
        {[5, 10, 20]
          .filter((item) => item !== postsPerPage)
          .map((data) => (
            <span
              key={"perView" + data}
              onClick={() => {
                setPostsPerPage(data);
              }}
            >
              {data}개씩 보기
            </span>
          ))}
      </div> */}
    </div>
  );
};

export default MuiPagination;
