import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// pages
import Home from "./pages/home/Home";
import Notice from "./pages/notice/Notice";

import NotFound from "./pages/error/NotFound";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FAQ from "./pages/faq/FAQ";

const queryClient = new QueryClient();

function App() {
  const routeMap = [
    {
      path: "/",
      Component: (
        <>
          <Header />
          <Home />
          <Footer />
        </>
      ),
    },
    {
      path: "/notice",
      Component: (
        <>
          <Header />
          <Notice />
          <Footer />
        </>
      ),
    },
    {
      path: "/faq",
      Component: (
        <>
          <Header />
          <FAQ />
          <Footer />
        </>
      ),
    },
  ];

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={NotFound}>
        <BrowserRouter>
          <Routes>
            {routeMap.map(({ path, Component }) => (
              <Route key={path} exact path={path} element={Component} />
            ))}
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
