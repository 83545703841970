import { useTranslation } from "react-i18next";
import "./ContentTitle.scss";

const ContentTitle = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className="content_title">
      <strong>{t(`${title}.title`)}</strong>
    </div>
  );
};

export default ContentTitle;
