import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ContentTitle from "../../components/ContentTitle";
import SubHeader from "../../components/SubHeader";
import "./FAQ.scss";
import React from "react";

const faqList = [
  { title: "칼리오페는 어떻게 구매하나요?", content: "잘 구매하시면 됩니다." },
  {
    title: "어디서 구매하는게 가장 싼가요?",
    content: "거래소를 잘 둘러보시면 됩니다.",
  },
];

const FAQ = () => {
  return (
    <div className="wrapper">
      <SubHeader title="FAQ" />
      <ContentTitle title="faq" />

      <div className="faq_wrap">
        {faqList.map((data) => (
          <Accordion key={data.title} style={{ marginBottom: "10px" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span>{data.title}</span>
            </AccordionSummary>
            <AccordionDetails>
              <p>{data.content}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
