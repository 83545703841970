import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../locales/i18n";

import "./Header.scss";

import LOGO from "../assets/logo/calliope_logo.png";

const Header = () => {
  const [ScrollY, setScrollY] = useState(0); // window 의 pageYOffset값을 저장
  const [ScrollActive, setScrollActive] = useState(false);

  const handleScroll = () => {
    if (ScrollY > 50) {
      setScrollY(window.pageYOffset);
      setScrollActive(true);
    } else {
      setScrollY(window.pageYOffset);
      setScrollActive(false);
    }
  };

  useEffect(() => {
    function scrollListener() {
      window.addEventListener("scroll", handleScroll);
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });

  const { t } = useTranslation();

  // 언어 변경하기
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className={ScrollActive ? "header scroll_header" : "header"}>
      <div>
        <Link to={"/"}>
          <img
            src={LOGO}
            alt="LOGO"
            className={ScrollActive ? undefined : `logo_filter`}
          />
        </Link>
      </div>

      <div>
        <span
          className={
            window.location.pathname === "/notice" ? "now_route" : undefined
          }
        >
          <Link to={"/notice"}>{t(`header.notice`)}</Link>
        </span>

        <span
          className={
            window.location.pathname === "/faq" ? "now_route" : undefined
          }
        >
          <Link to={"/faq"}>{t(`header.faq`)}</Link>
        </span>

        <span
          onClick={() => {
            changeLanguage("ko");
          }}
        >
          {/* <img className="flag" src={KOR} alt={t(`header.ko`)} /> */}
          {t(`header.ko`)}
        </span>

        <span
          onClick={() => {
            changeLanguage("en");
          }}
        >
          {/* <img className="flag" src={ENG} alt={t(`header.en`)} /> */}
          {t(`header.en`)}
        </span>
      </div>
    </div>
  );
};

export default Header;
